import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

const options = {
  aspectRatio: 1,
  scales: {
    x: {
      min: -1500,
      max: 1500,
    },
    y: {
      beginAtZero: false,
      min: -1500,
      max: 1500,
    },
  },
};

function BubbleChart(props) {
  const length = props.history.length;

  if (props.history.length === 0) {
    return <h1>...</h1>;
  }

  let currentPos = {};
  let historyData = [];
  let historyData50 = [];
  let historyData100 = [];
  let historyData250 = [];

  if (length > 0) {
    currentPos = { x: props.history[0].xPos, y: props.history[0].zPos, r: 8 };
  }
  if (length > 1) {
    for (let i = 1; i < length && i < 51; i++) {
      if (props.history[i].xPos === props.history[i - 1].xPos && props.history[i].yPos === props.history[i - 1].yPos) { continue; }
      historyData.push({ x: props.history[i].xPos, y: props.history[i].zPos, r: 5 });
    }
  }
  if (length > 50) {
    for (let i = 51; i < length && i < 101; i++) {
      if (props.history[i].xPos === props.history[i - 1].xPos && props.history[i].yPos === props.history[i - 1].yPos) { continue; }
      historyData50.push({ x: props.history[i].xPos, y: props.history[i].zPos, r: 4 });
    }
  }
  if (length > 100) {
    for (let i = 101; i < length && i < 251; i++) {
      if (props.history[i].xPos === props.history[i - 1].xPos && props.history[i].yPos === props.history[i - 1].yPos) { continue; }
      historyData100.push({ x: props.history[i].xPos, y: props.history[i].zPos, r: 3 });
    }
  }
  if (length > 250) {
    for (let i = 251; i < length; i++) {
      if (props.history[i].xPos === props.history[i - 1].xPos && props.history[i].yPos === props.history[i - 1].yPos) { continue; }
      historyData250.push({ x: props.history[i].xPos, y: props.history[i].zPos, r: 2 });
    }
  }

  let data = {
    datasets: [
      {
        label: 'Most Recent',
        data: [currentPos],
        backgroundColor: 'rgba(0,191,255, 0.5)',
      },
      {
        label: '2-50 Most Recent',
        data: historyData,
        backgroundColor: 'rgba(0, 255, 133, 0.4)',
      },
      {
        label: '51-100 Most Recent',
        data: historyData50,
        backgroundColor: 'rgba(255, 165, 0, 0.2)',
      },
      {
        label: '101-250 Most Recent',
        data: historyData100,
        backgroundColor: 'rgba(255, 255, 55, 0.2)',
      },
      {
        label: '251+ Most Recent',
        data: historyData250,
        backgroundColor: 'rgba(255, 55, 55, 0.2)',
      },
    ],
  };

  if (props.location?.xPos === undefined) { return <h1> </h1>; }
  if (props.location === null) { return <h1>ERROR</h1>; }

  return (
    <div className="Canvas">
      <Bubble options={options} data={data} />
    </div>
  );
}

export default BubbleChart;
