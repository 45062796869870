import React, { useState, useEffect } from 'react';
import SelectedPlayerOverview from './constellation/SelectedPlayerOverview';
import PlayerList from './constellation/PlayerList';
import { getPlayerHistory, getPlayerLocation } from './constellation/apiFunctions';
import BubbleChart from './constellation/BubbleChart';
import DimensionList from './constellation/DimensionList';
import InstancesWithinRadius from './constellation/InstancesWithinRadius';
import { Box, Typography, Paper, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Constellation(props) {
  const [aligned, setAligned] = useState('');
  const [currentLocation, setCurrentLocation] = useState([]);
  const [history, setHistory] = useState([]);
  const [dimension, setDimension] = useState('overworld');
  const [targetCoordinates, setTargetCoordinates] = useState({ x: 0, y: 0, z: 0 });
  const [radius, setRadius] = useState(0);
  const [withinRadius, setWithinRadius] = useState([]);

  useEffect(() => {
    if (aligned) {
      const interval = setInterval(() => getInformation(aligned), 5000);
      return () => clearInterval(interval);
    }
  }, [aligned, dimension]);

  useEffect(() => {
    if (aligned) {
      getInformation(aligned);
    }
  }, [dimension, aligned]);

  useEffect(() => {
    if (history.length > 0) {
      calculateWithinRadius();
    }
  }, [history, targetCoordinates, radius]);

  function setAlignedPlayer(player) {
    setAligned(player);
    getInformation(player);
  }

  function setAlignedDimension(newDimension) {
    setDimension(newDimension);
  }

  function getInformation(player) {
    if (!player) return;
    getPlayerLocation(setCurrentLocation, player);
    getPlayerHistory(setHistory, player, 1000, dimension);
  }

  function handleTargetCoordinateChange(event) {
    const { name, value } = event.target;
    setTargetCoordinates((prev) => ({
      ...prev,
      [name]: value ? Number(value) : '',
    }));
  }

  function handleRadiusChange(event) {
    setRadius(Number(event.target.value));
  }

  function calculateWithinRadius() {
    const filtered = history.filter((log) => {
      const dx = log.xPos - targetCoordinates.x;
      const dy = log.yPos - targetCoordinates.y;
      const dz = log.zPos - targetCoordinates.z;
      const distance = Math.sqrt(dx ** 2 + dy ** 2 + dz ** 2);
      return distance <= radius;
    });
    setWithinRadius(filtered);
  }

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <Paper elevation={3} sx={{ p: 2, backgroundColor: '#212121' }}>
            <PlayerList handleSelection={setAlignedPlayer} token={props.token} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={5}>
          <Paper elevation={3} sx={{ p: 2, backgroundColor: '#212121' }}>
            <DimensionList setAlignedDimension={setAlignedDimension} token={props.token} />
          </Paper>
        </Grid>
      </Grid>
      {aligned && (
        <>
          <Paper elevation={3} sx={{ p: 2, backgroundColor: '#212121' }}>
            <SelectedPlayerOverview aligned={aligned} location={currentLocation} />
          </Paper>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Additional Searchable Parameters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Search Radius Around Target</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <InstancesWithinRadius
                    targetCoordinates={targetCoordinates}
                    radius={radius}
                    handleTargetCoordinateChange={handleTargetCoordinateChange}
                    handleRadiusChange={handleRadiusChange}
                    withinRadius={withinRadius}
                  />
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          </Accordion>
          <BubbleChart location={currentLocation} history={history} dimension={dimension} />
        </>
      )}
    </Box>
  );
}

export default Constellation;
